
import * as _ from "lodash"
import { directive as onClickaway } from "vue-clickaway2"
import Multiselect from "vue-multiselect"
import SandwichSearch from "@/dashboard/search/sandwich-search"
import PugImg from "@/dashboard/pug-img"
import tippy from "tippy.js"
import "tippy.js/dist/tippy.css"
import "tippy.js/animations/scale.css"
import { removeAndAlterIds } from "@/dashboard/utils/site-builder"
import { EventBus } from "@/utils/event-bus"
import { mapGetters } from "vuex"

export default {
  name: "BasePopoverLollipop",
  components: { SandwichSearch, PugImg, Multiselect },
  directives: {
    onClickaway,
  },
  props: {
    /**
     * Placement of popover
     */
    placement: {
      type: String,
      default: "top",
    },
    /**
     * Boundary of popover
     */
    boundary: {
      type: String,
      default: "viewport",
    },
    /**
     * The array of Objects to be displayed in the popover.
     * The objects should have two properties, img and text
     * This is required.
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     * The trigger to be used to show the popover. Default is hover
     */
    trigger: {
      type: String,
      default: "click blur",
    },
    /**
     * The size of the popover. If md the height is 300px, if sm the height is 100px
     */
    size: {
      type: String,
      default: "md",
    },
    /**
     * The ID of the template element that should show the popover when hovered over
     * it's important to keep this ID unique when using this component
     * multiple times in the same page (e.g. assign it using a v-for)
     * This is required.
     */
    activatorId: {
      type: String,
      required: true,
    },
    /**
     * The title of the popover. Default ''
     */
    title: {
      type: String,
      default: "",
    },
    /**
     * Toggles the vertical margin on the activator
     */
    addVerticalMargin: {
      type: Boolean,
      default: true,
    },
    /**
     * The number of columns to split the items into. Default is 3
     * You should pass a number that 12 is multiple of
     */
    columns: {
      type: Number,
      default: 3,
    },
    /**
     * If set to true, SandwhichSearch will be displayed to filter the items.
     * Default is false
     */
    enableSearch: {
      type: Boolean,
      default: false,
    },
    /**
     * Will stay open after selecting a single item
     */
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    /**
     * The property that will be used to search through the items.
     * Default is 'text'
     */
    searchBy: {
      type: String,
      default: "text",
    },
    /**
     * If set to true the popover will be given a max-height of 300px
     * and the content will be scrollable
     */
    enableScroll: {
      type: Boolean,
      default: true,
    },
    isGrouped: {
      type: Boolean,
      default: false,
    },
    groupBy: {
      type: String,
      default: "",
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    /**
     * Allow pasting from browser clipboard
     */
    allowPaste: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterResult: null,
      itemsArray: [],
      itemsObject: {},
      isVisible: false,
      groupOptions: [],
      selectedGroup: null,
      show: false,
    }
  },
  computed: {
    ...mapGetters({
      isSteaveAccount: "accounts/isSteaveAccount",
    }),
    colSize() {
      return 12 / this.columns
    },
    filteredItems() {
      if (this.filterResult) {
        return this.filterResult
      }

      return this.itemsArray
    },
    getPopoverSize() {
      return this.size === "sm" ? "popover-sm" : "popover-md"
    },
    filteredObject() {
      if (this.filterResult && this.isGrouped) {
        if (this.selectedGroup && !_.isEmpty(this.selectedGroup)) {
          const groups = _.groupBy(this.filterResult, this.groupBy)
          const filteredGroups = _.pick(groups, this.selectedGroup)
          return filteredGroups
        } else {
          return _.groupBy(this.filterResult, this.groupBy)
        }
      } else if (this.selectedGroup && !_.isEmpty(this.selectedGroup)) {
        const filteredGroups = _.pick(this.itemsObject, this.selectedGroup)
        return filteredGroups
      }
      return this.itemsObject
    },
  },
  watch: {
    items(newVal, oldVal) {
      if (newVal && newVal.length && newVal !== oldVal) {
        this.init()
      }
    },
  },
  mounted() {
    this.init()
    // this.$root.$emit("bv::show::popover", this.activatorId)
  },
  methods: {
    init() {
      setTimeout(() => {
        if (this.isGrouped) {
          this.itemsObject = _.groupBy(this.items, this.groupBy)
          this.groupOptions = Object.keys(this.itemsObject)
        }

        this.itemsArray = this.items
      }, 200)
    },
    filterItems(results) {
      if (results && results.length === this.itemsArray.length) {
        this.filterResult = null
      } else {
        this.filterResult = results
      }
    },
    onClick() {
      // if (this.trigger.includes("click")) {
      //   this.$root.$emit("bv::show::popover", this.activatorId)
      //   this.isVisible = true
      // }
    },
    onClickAway() {
      if (this.trigger.includes("click")) {
        this.$root.$emit("bv::hide::popover", this.activatorId)
        this.isVisible = false
      }
    },
    onHover() {
      if (this.trigger.includes("hover")) {
        this.$root.$emit("bv::show::popover", this.activatorId)
        this.isVisible = true
      }
    },
    onMouseLeave() {
      if (this.trigger.includes("hover")) {
        this.$root.$emit("bv::hide::popover", this.activatorId)
        this.isVisible = false
      }
    },
    onSearchCleared() {
      this.filterResult = null
    },
    onItemClick(item) {
      /**
       * Event triggered when clicking on an item inside the popover
       * it is sent along with the item that triggered it
       *
       * @event onItemClick
       * @type {object}
       */
      this.$emit("onItemClick", item)
      if (this.closeOnSelect === true) {
        this.$root.$emit("bv::hide::popover", this.activatorId)
        this.$bvModal.hide(`modal-add-component-${ this.activatorId }`)
      }
    },
    onOpenModal() {
      // if (!this.isModal) {
      //   return
      // } else {
      //   this.$bvModal.show(`modal-add-component-${ this.activatorId }`)
      //   this.$nextTick(() => {
      //     tippy(`.tippy-lollipop`, {
      //       arrow: true,
      //       animation: "scale",
      //       trigger: "mouseenter click focus",
      //       allowHTML: true,
      //       placement: "top",
      //       theme: "light-border",
      //       hideOnClick: false,
      //       onClickOutside(instance, event) {
      //         instance.hide()
      //       },
      //     })
      //   })
      // }
      EventBus.$emit('show_editor_sidebar')
    }
  },
}
